<template>
  <div v-if="currentSlot.slot" class="detailSesi">
    <a-modal
      title="Update Assignment"
      :visible="modalVisible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      :maskStyle="{
        opacity: 0.5,
        backgroundColor: 'black',
      }"
      okText="Update"
      :okButtonProps="{
        props: {
          shape: 'round',
        },
      }"
      :cancelButtonProps="{
        props: {
          shape: 'round',
        },
      }"
    >
      <p>Are you sure to update your assignment?</p>
    </a-modal>
    <div id="coverDetail">
      <h2>
        {{ currentSlot.slot.kela.tingkat }} -
        {{ currentSlot.slot.kela.simbol }} |
        {{ currentSlot.slot.mata_pelajaran.nama }}
      </h2>
      <div>
        Session {{ currentSlot.week.week }} / 24 |
        {{ currentSlot.topik.nama ? topik(currentSlot.topik.nama) : "-" }} |
        {{ sessionDate }}, {{ sessionTime }}
      </div>
    </div>
    <div
      v-if="isInSubjectHours && liveClass.zoom_setting"
      class="countdownTImer"
    >
      <h4>SESSION TIME LEFT</h4>
      <h2>{{ formattedDuration }}</h2>
    </div>
    <div id="contentDetail">
      <div class="d-flex flex-column flex-lg-row align-items-center">
        <div class="w-100 w-lg-75">
          <a-menu class="menuSchedule" v-model="current" mode="horizontal">
            <a-menu-item key="live class" class="subMenuDetail">
              <a-icon
                type="video-camera"
                style="color: #ff0000; font-size: 22px"
                v-if="isLive && isNow"
              />
              {{ isLive && isNow ? "LIVE CLASS" : "NOTES & DETAILS" }}
            </a-menu-item>
            <a-menu-item key="materials" class="subMenuDetail"
              >MATERIALS</a-menu-item
            >
            <a-menu-item key="assignments" class="subMenuDetail"
              >ASSIGNMENTS</a-menu-item
            >
          </a-menu>
        </div>
        <div
          v-if="
            current[0] === 'assignments' &&
            (currentSlot.tipe_assignment === 'Essay' ||
              currentSlot.tipe_assignment === 'Multiple Choice') &&
            !assignmentStudent.tugas_nilai
          "
          class="w-auto d-flex flex-column flex-md-row mt-2 ml-3"
        >
          <a-button
            shape="round"
            :type="doAssignment ? 'danger' : 'primary'"
            :icon="doAssignment ? 'close' : 'edit'"
            :disabled="attendanceIsNull ? true : false"
            class="mb-3 mb-lg-0 mr-0 mr-md-3"
            @click="doAssignmentClick"
            >{{ doAssignment ? "CANCEL" : "DO ASSIGNMENT" }}</a-button
          >
          <a-button
            v-if="doAssignment"
            @click.prevent="saveDoAssignment"
            style="background-color: #41b883; color: white"
            shape="round"
            icon="save"
            >SUBMIT</a-button
          >
        </div>
      </div>
      <div>
        <!-- <transition-group name="slide-fadein-up"> -->
        <LiveClass
          v-if="liveClass.zoom_setting"
          v-show="current[0] === 'live class'"
          :liveClass="liveClass"
          :isStudent="true"
          :isNow="isNow"
          @addUploadedImages="addUploadedImages"
        />
        <Materials
          v-show="materials && current[0] === 'materials'"
          :videoUrls="videoUrls"
          :materials="materials"
          :fileMaterials="fileMaterials"
        />
        <Assignments
          key="3"
          v-show="current[0] === 'assignments'"
          :session="currentSlot"
          :taskType="currentSlot.tipe_assignment"
          :doAssignment="doAssignment"
          :assignmentStudent="assignmentStudent"
          :attendanceIsNull="attendanceIsNull"
          @changeAttendanceIsNull="changeAttendanceIsNull"
          @changedEssayAnswer="changedEssayAnswer"
          @changedMultipleChoiceAnswer="changedMultipleChoiceAnswer"
        />
      </div>
      <!-- </transition-group> -->
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import LiveClass from '@/components/app/DetailSchedule/LiveClass'
import Materials from '@/components/app/DetailSchedule/Materials'
import Assignments from '@/components/app/DetailSchedule/Assignments'
// import $ from 'jquery'
// import { ZoomMtg } from '@zoomus/websdk'

export default {
  data() {
    return {
      attendanceIsNull: false,
      doAssignment: false,
      idWeek: null,
      isEdit: false,
      src: '',
      meetConfig: {},
      signature: {},
      liveClass: {},
      assignmentStudent: {},
      essayAnswer: '',
      multipleChoiceAnswer: '',
      modalVisible: false,
      confirmLoading: false,
      time: moment(),
      nextTime: moment(),
      duration: 0,
      formattedDuration: '00:00:00',
      uploadedImages: [],
    }
  },
  components: {
    LiveClass,
    Materials,
    Assignments,
  },
  methods: {
    addUploadedImages(payload) {
      this.uploadedImages = payload
    },
    makeDuration() {
      const time = moment(`${this.currentSlot.tanggal_sesi} ${this.currentSlot.slot.model_jadwal.jam_mulai}`, 'YYYY-MM-DD HH:mm')
      const nextTime = moment(time, 'HH:mm').add(this.currentSlot.slot.model_jadwal.durasi * this.currentSlot.slot.jam_pelajaran, 'minutes').locale('id')
      const currentTime = moment().locale('id')
      const diffTime = nextTime - currentTime
      this.duration = moment.duration(diffTime, 'milliseconds')
      // console.log('masuk sini dessh')
      const timerInterval = setInterval(() => {
        if (this.duration <= 0) {
          if (this.currentSlot.slot.posisi === 4 || (this.currentSlot.slot.posisi === 3 && this.currentSlot.slot.jam_pelajaran === 2)) {
            setTimeout(() => {
              this.$store.dispatch('slot/FETCH_CURRENT_SLOT', { idSession: this.idNextSession })
                .then(_ => {
                  this.makeDuration()
                  this.time = moment(`${this.currentSlot.tanggal_sesi} ${this.currentSlot.slot.model_jadwal.jam_mulai}`, 'YYYY-MM-DD HH:mm')
                  this.nextTime = moment(this.time, 'HH:mm').add(this.currentSlot.slot.model_jadwal.durasi * this.currentSlot.slot.jam_pelajaran, 'minutes')
                })
              this.$store.dispatch('attendance/FETCH_ATTENDANCE_BY_ID_MURID', {
                idMurid: this.user.id,
                idSession: this.idNextSession,
              })
                .then(data => {
                  if (data.data) {
                    this.assignmentStudent = data.data
                    this.essayAnswer = this.assignmentStudent.essay_answer
                    this.multipleChoiceAnswer = this.assignmentStudent.jawaban_murid
                  }
                })
            }, 900000)
          } else {
            if (this.idNextSession && this.isInSubjectHours) {
              this.$store.dispatch('slot/FETCH_CURRENT_SLOT', { idSession: this.idNextSession })
                .then(_ => {
                  this.makeDuration()
                  this.time = moment(`${this.currentSlot.tanggal_sesi} ${this.currentSlot.slot.model_jadwal.jam_mulai}`, 'YYYY-MM-DD HH:mm')
                  this.nextTime = moment(this.time, 'HH:mm').add(this.currentSlot.slot.model_jadwal.durasi * this.currentSlot.slot.jam_pelajaran, 'minutes')
                })
              this.$store.dispatch('attendance/FETCH_ATTENDANCE_BY_ID_MURID', {
                idMurid: this.user.id,
                idSession: this.idNextSession,
              })
                .then(data => {
                  if (data.data) {
                    this.assignmentStudent = data.data
                    this.essayAnswer = this.assignmentStudent.essay_answer
                    this.multipleChoiceAnswer = this.assignmentStudent.jawaban_murid
                  }
                })
            }
          }
          clearInterval(timerInterval)
        } else {
          // console.log('masuk sini deh')
          this.duration = moment.duration(this.duration - 1000, 'milliseconds')
          this.formattedDuration = `${this.duration.hours() >= 10 ? '' : '0'}${this.duration.hours()}:${this.duration.minutes() >= 10 ? '' : '0'}${this.duration.minutes()}:${this.duration.seconds() >= 10 ? '' : '0'}${this.duration.seconds()}`
        }
      }, 1000)
      // return this.nextTime.diff(this.time, 'miliseconds')
    },
    findWeekSlot(idSlot) {
      const { slots } = this.$store.state.slot
      slots.map(slot => {
        if (slot.id === this.$route.params.id) {
          this.idWeek = slot.id
        }
      })
    },
    topik(text) {
      let string = ''
      if (text.length > 25) {
        string = text.substring(0, 50) + '...'
      } else {
        string = text
      }
      return string
    },
    saveDoAssignment() {
      this.modalVisible = true
    },
    handleOk(e) {
      // e.preventDefault()
      let apiIsSuccess = false
      this.confirmLoading = true
      const tipeAssignment = this.currentSlot.tipe_assignment
      let answer
      if (tipeAssignment === 'Essay') {
        answer = this.essayAnswer
      } else if (tipeAssignment === 'Multiple Choice') {
        answer = this.multipleChoiceAnswer
      }
      this.$store.dispatch('sesi/UPDATE_ANSWER', {
        tipeAssignment: tipeAssignment,
        answer: answer,
        idMurid: this.user.id,
        idSesi: this.currentSlot.id,
      })
        .then(isSuccess => {
          apiIsSuccess = isSuccess
          return this.$store.dispatch('attendance/FETCH_ATTENDANCE_BY_ID_MURID', {
            idMurid: this.user.id,
            idSession: this.currentSlot.id,
          })
        })

        .then(data => {
          setTimeout(() => {
            this.doAssignment = false
            this.modalVisible = false
            this.confirmLoading = false
            if (apiIsSuccess) {
              this.$notification.success({
                message: 'Successfully update notes',
              })
            } else {
              this.$notification.error({
                message: 'Sorry.',
                description: 'Notes can\'t be updated right now. Try again later.',
              })
            }

            if (data.data) {
              this.assignmentStudent = data.data
              this.essayAnswer = this.assignmentStudent.essay_answer
              this.multipleChoiceAnswer = this.assignmentStudent.jawaban_murid
            }
          }, 2000)
        })
    },
    handleCancel(e) {
      // e.preventDefault()
      this.modalVisible = false
    },
    doAssignmentClick() {
      if (this.doAssignment) {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure to cancel this assignment ? Your changes will be lost </div>
          ),
          onOk: () => {
            this.doAssignment = false
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'danger',
          okText: 'Yes',
          cancelText: 'No',
        })
      } else {
        this.doAssignment = true
      }
    },
    changeAttendanceIsNull() { this.attendanceIsNull = true },
    changedEssayAnswer(answer) {
      // console.log(answer)
      this.essayAnswer = answer
    },
    changedMultipleChoiceAnswer(answer) {
      // console.log(answer)
      this.multipleChoiceAnswer = answer
      // console.log('Multiple Choice', this.multipleChoiceAnswer)
    },
  },
  created() {
    const idSession = this.$route.params.id
    // this.findWeekSlot(idSlot)
    this.$store.dispatch('attendance/FETCH_ATTENDANCE_BY_ID_MURID', {
      idMurid: this.user.id,
      idSession,
    })
      .then(data => {
        if (data.data) {
          this.assignmentStudent = data.data
          this.essayAnswer = this.assignmentStudent.essay_answer
          this.multipleChoiceAnswer = this.assignmentStudent.jawaban_murid
        }
      })
    this.$store.dispatch('slot/FETCH_CURRENT_SLOT', { idSession })
      .then((zoomSetting) => {
        this.time = moment(`${this.currentSlot.tanggal_sesi} ${this.currentSlot.slot.model_jadwal.jam_mulai}`, 'YYYY-MM-DD HH:mm')
        this.nextTime = moment(this.time, 'HH:mm').add(this.currentSlot.slot.model_jadwal.durasi * this.currentSlot.slot.jam_pelajaran, 'minutes')
        this.liveClass = {
          zoom_setting: this.currentSlot.zoom_setting,
          student: this.currentSlot.slot.kela.murids,
          kelas: this.currentSlot.slot.kela,
        }
        if (zoomSetting && this.isNow) {
          this.makeDuration()
          // ZOOM CONFIG
          // console.log(this.currentSlot.slot.kela.zoom_password, 'masukkk')
          // this.meetConfig = {
          //   apiKey: this.currentSlot.slot.kela.zoom_api_key,
          //   apiSecret: this.currentSlot.slot.kela.zoom_api_secret,
          //   meetingNumber: this.currentSlot.slot.kela.zoom_url,
          //   userName: `${this.currentSlot.slot.kela.tingkat}-${this.currentSlot.slot.kela.simbol} / ${this.user.nama}`,
          //   passWord: this.currentSlot.slot.kela.zoom_password,
          //   leaveUrl: window.location.origin,
          //   role: 0,
          // }

          // this.signature = ZoomMtg.generateSignature({
          //   meetingNumber: this.meetConfig.meetingNumber,
          //   apiKey: this.meetConfig.apiKey,
          //   apiSecret: this.meetConfig.apiSecret,
          //   role: this.meetConfig.role,
          //   success: function(res) {
          //     // eslint-disable-next-line
          //     console.log('masuk')
          //     console.log('success signature: ' + res.result)
          //   },
          // })

          // ZoomMtg.init({
          //   leaveUrl: window.location.origin,
          //   isSupportAV: true,
          //   isSupportChat: false,
          //   disableRecord: true,
          //   screenShare: true,
          //   success: () => {
          //     console.log('masuk init')
          //     console.log(this.meetConfig)
          //     ZoomMtg.join({
          //       meetingNumber: this.meetConfig.meetingNumber,
          //       userName: this.meetConfig.userName,
          //       signature: this.signature,
          //       apiKey: this.meetConfig.apiKey,
          //       userEmail: 'jojo@digitala.id',
          //       passWord: this.meetConfig.passWord,
          //       success: (res) => {
          //         console.log('masuk join')
          //         this.$notification.success({
          //           message: 'Success',
          //           description: 'Successfully joining zoom. Good luck for your class!',
          //         })
          //         // eslint-disable-next-line
          //         console.log("join meeting success")
          //         localStorage.setItem('isLive', true)
          //         $('#zmmtg-body').children('#zmmtg-root').append($('.meeting-app'))
          //         $('#wc-container-left').children('div').attr('style', 'height: 100%')
          //         // $("[aria-label='start sending my video']").click(function() {
          //         //   console.log('oke')
          //         // })
          //         $('button.footer__leave-btn.ax-outline').hide()
          //         $("[aria-label='start sending my video']").click()
          //       },
          //       error: (res) => {
          //         this.$notification.error({
          //           message: 'Error',
          //           description: 'Sorry. There must be a trouble when joining class.',
          //         })
          //         console.log(res)
          //       },
          //     })
          //   },
          //   error: (res) => {
          //     this.$notification.error({
          //       message: 'Error',
          //       description: 'Sorry. There must be a trouble when joining class.',
          //     })
          //     console.log(res)
          //   },
          // })
        } else {
          this.$store.commit('live/SET_STATE', {
            isLive: false,
          })
        }
        this.$store.dispatch('slot/FETCH_FILE_MATERIAL', { idTopik: this.currentSlot.topik.id })
      })
  },
  computed: {
    currentSlot() { return this.$store.state.slot.currentSlot },
    sessionDate() { return moment(this.currentSlot.tanggal_sesi, 'YYYY-MM-DD').locale('id').format('DD MMMM YYYY') },
    sessionTime() { return moment(this.currentSlot.slot.model_jadwal.jam_mulai, 'HH:mm').locale('id').format('HH:mm') },
    materials() { return this.currentSlot.topik },
    isLoading() { return this.$store.state.isLoading },
    videoUrls() {
      const data = []
      for (let i = 1; i <= 5; i++) {
        if (this.materials[`video_url${i}`] && this.materials[`video_url${i}`] !== '') {
          data.push(this.materials[`video_url${i}`])
        }
      }
      return data
    },
    fileMaterials() {
      return this.$store.state.slot.fileMaterials
    },
    isLive() { return this.currentSlot.zoom_setting },
    user() { return this.$store.state.user.user },
    isNow() {
      // return true
      return moment().days() === this.time.days()
      // return moment().isSame(this.time, 'days') && moment().isBefore(moment('13:00', 'HH:mm')) && moment().isAfter(moment('06:00', 'HH:mm'))
    },
    idNextSession() {
      return this.$store.state.slot.idNextSession
    },
    isInSubjectHours() {
      return moment().isBetween(this.time, this.nextTime, undefined, '[)')
    },
    isDone() {
      // console.log(moment().isBetween(time, nextTime, undefined, '[]'))
      return this.nextTime < moment() && moment().isAfter(moment('13:00', 'HH:mm'))
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
    current: {
      get: function() {
        return this.$store.state.menu.currentDetailScheduleMenu
      },
      set: function(value) {
        this.$store.commit('menu/SET_STATE', {
          currentDetailScheduleMenu: value,
        })
      },
    },
  },
  // beforeRouteLeave (to, from, next) {
  //   this.$store.dispatch('attendance/FETCH_ATTENDANCE_BY_ID_MURID', {
  //     idMurid: this.user.id,
  //     idSession: this.currentSlot.id,
  //   })
  //     .then(({ data }) => {
  //       let unlinkArr = []
  //       if (data.notes && this.uploadedImages.length) {
  //         const regexImages = /(images\/).+?(?=")/gm
  //         // let image
  //         // const regexImages = /(?<=images\/).+?(?=")/gm
  //         // let image
  //         const arrImageOld = data.notes.match(regexImages)
  //         if (arrImageOld) {
  //           const arrImage = arrImageOld.map(image => {
  //             return image[0].split('images/')[1]
  //           })
  //           for (let j = 0; j < this.uploadedImages.length; j++) {
  //             const image = this.uploadedImages[j]
  //             for (let i = 0; i < arrImage; i++) {
  //               if (arrImage[i] === image) {
  //                 break
  //               } else if (i === arrImage.length - 1 && arrImage[i] !== image) {
  //                 unlinkArr.push(image)
  //               }
  //             }
  //           }
  //         }
  //       } else if (!data.notes && this.uploadedImages.length) {
  //         unlinkArr = this.uploadedImages
  //       }
  //       console.log(unlinkArr)
  //       if (unlinkArr.length) {
  //         this.$store.dispatch('UNLINK_IMAGE', { unlinkImages: unlinkArr })
  //       }
  //     })
  //   next()
  // },
}
</script>
<style lang="scss">
.detailSesi {
  overflow-x: hidden;
  #coverDetail {
    width: 100%;
    min-height: 145px;
    background-color: #008080;
    border-radius: 10px;
    padding: 40px;
    color: #ffffff;
    /* opacity: 0.8;
    z-index: 10; */
  }

  .countdownTImer {
    width: 211px;
    height: 120px;
    background-color: white;
    position: fixed;
    bottom: 10%;
    right: 5%;
    z-index: 99999;
    box-shadow: 0px 5px 15px #6d807b80;
    display: flex;
    flex-direction: column;
    padding: 30px;
    h4 {
      color: #041930;
    }
    h2 {
      margin: 0;
      color: #ff0000;
    }
  }

  h2 {
    color: #ffffff;
  }

  .menuSchedule {
    margin-bottom: 35px;
  }

  #contentDetail {
    width: 100%;
    min-height: 20vh;
    background-color: white;
    padding: 40px;
    z-index: 11;
    margin-top: -10px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #0419301a;
    margin-left: 1px;
    @media (max-width: 425px) {
      padding: 20px;
    }
  }

  .subMenuDetail {
    font-family: "Mukta", "sans-serif";
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 1.5px;
    /* color: #041930; */
  }

  .form-submit {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    padding: 10px 45px;
    background: #347c2c 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
    border-style: none;
    color: #ffffff;
  }

  .form-submit:active {
    border-style: none;
  }
}
</style>
